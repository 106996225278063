.container {
	position: relative;
	top: 100px;
	margin: auto;
}

input[type='file'] {
	display: none;
}

.pb{
	display: none;
	opacity: 0;
}
